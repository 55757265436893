import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import * as styles from './styles/received.module.css'
import Button from '../components/button'
import ThankYouIcon from '../components/svgs/ThankYou'
import { getTextCopy } from '../helpers'

const Received = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
  } = data.receivedPage
  const { homePageSlug } = data.homePage
  const extraMeta = [
    {
      name: `robots`,
      content: `noindex`,
    },
  ]

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
        extraMeta={extraMeta}
      />
      <Layout>
        <Section>
          <div className={styles.container}>
            <h1 className={styles.heading}>
              {getTextCopy(content, 'page.received.header')}
            </h1>
            <p className={styles.tagline}>
              {getTextCopy(content, 'page.received.subheader')}
            </p>
            <div className={styles.image}>
              <ThankYouIcon />
            </div>
            <Link to={homePageSlug}>
              <Button action="secondary">
                {getTextCopy(content, 'page.received.button')}
              </Button>
            </Link>
          </div>
          <form
            style={{ display: `none` }}
            name="recaptured-apply"
            data-netlify="true"
            encType="multipart/form-data"
          >
            <input type="hidden" name="form-name" value="recaptured-apply" />
            <input type="hidden" name="score" value="" />
            <input type="hidden" name="session" value="" />
            <input type="hidden" name="job" value="" />
            <input type="hidden" name="department" value="" />
            <input type="hidden" name="name" value="" />
            <input type="hidden" name="email" value="" />
            <input type="hidden" name="phone" value="" />
            <input type="hidden" name="cover" value="" />
            <input type="hidden" name="resume" value="" />
          </form>
        </Section>
      </Layout>
    </>
  )
}

export default Received

export const query = graphql`
  {
    receivedPage: contentfulPage(name: { eq: "page.received" }) {
      ...pageFields
    }
    homePage: contentfulPage(name: { eq: "page.home" }) {
      homePageSlug: slug
    }
  }
`
